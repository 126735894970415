import Image from 'next/image'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useState } from 'react'

import Link from 'next/link'
import { useSelector } from 'react-redux'
import { useAuth } from '~/components/Auth/AuthProvider'
import { logout } from '~/components/Auth/Logout'
import { Button, Spacer } from '~/components/Common'
import { DropdownWrapper } from '~/components/Dropdown'
import { Icon } from '~/components/Icon'
import { Sidebar, SidebarListItem } from '~/components/Sidebar'
import { useSubscription } from '~/components/Subscription/SubscriptionProvider'
import { responsiveBreakpointMap } from '~/constants'
import { COLORS } from '~/constants/colors'
import $amplitude from '~/lib/amplitude'
import $posthog from '~/lib/posthog'
import $sentry from '~/lib/sentry'
import locale from '~/locale'
import { RootState } from '~/redux/store'
import { delayedCallback, getCurrentBusiness, setCurrentBusiness } from '~/utils'
import { DesktopPrompt } from '../DesktopPrompt'

interface DashboardProps {
    /**
     * Extend element
     */
    children: JSX.Element | JSX.Element[]
    /**
     * Curent route
     */
    pathname?: string
    /**
     * Hide navigation
     */
    hideNavigation?: boolean
    /**
     * Show Mobile version
     */
    showMobile?: boolean
}

export const Dashboard = ({ children, pathname = '', hideNavigation, showMobile = false }: DashboardProps) => {
    const language = useSelector((state: RootState) => state.language.language)
    // Assuming locale has a specific type, e.g. LocaleType
    const localeText = (locale[language as keyof typeof locale] || locale.en) as any
    const { profile, businesses, currentBusiness, checkAccess } = useAuth()
    const { execFeature, getSubscription } = useSubscription()
    const [active, setActive] = useState(false)
    const router = useRouter()
    const parentRoute = '/' + pathname.split('/')[2]
    const [showMobileSidebar] = useState(false)
    const [profileDropdownState, setProfileDropdownState] = useState(false)

    const [isSubscribed] = getSubscription()

    const handClickItem = (slug: string) => {
        if (slug === '') {
            return
        }

        router.push(`/business${slug}`)
    }

    const haveAccessToManageStaff = () => {
        return currentBusiness()?.roles === 'owner'
    }

    useEffect(() => {
        const business = currentBusiness()
        if (business)
            $posthog.group('business', business.businessId, {
                name: business.name,
                category: business.category,
                subscription_package: business.subscription?.productId.split('_')[1] || 'free',
                subscription_start_date: business.subscription?.startDate,
                subscription_expired_date: business.subscription?.endDate
            })
    }, [currentBusiness()])

    if (window.innerWidth < responsiveBreakpointMap.md && !showMobile) {
        return (
            <>
                <DesktopPrompt localeText={localeText}></DesktopPrompt>
            </>
        )
    }

    // navigation-constant
    const navigationMenu = [
        // {
        //   text: locale.id.common.home,
        //   slug: '/',
        //   icon: <Icon name="home" width={22} height={22}></Icon>
        // },
        // {
        //     // text: localeText?.accounting?.dashboard_title,
        //     text: localeText?.report?.title,
        //     slug: '/report',
        //     icon: <Icon name="money-outline" width={22} height={22}></Icon>
        // },
        {
            text: localeText?.report?.title,
            slug: '/report',
            icon: <Icon name="report" width={22} height={22}></Icon>,
            submenu: [
                {
                    text: localeText?.short?.finance,
                    slug: '/report',
                    icon: <Icon name="money-outline" width={22} height={22}></Icon>
                },
                {
                    text: localeText?.short?.stock,
                    slug: '/stock-report',
                    icon: <Icon name="report" width={22} height={22}></Icon>
                },
                {
                    text: localeText?.common?.customer,
                    slug: '/customer-report'
                }
            ]
        },
        {
            text: localeText?.common?.product,
            slug: '/product',
            icon: <Icon name="archive-outline" width={22} height={22}></Icon>
        },
        {
            text: localeText?.common?.stock_in,
            slug: '/stock-in',
            icon: <Icon name="download" width={22} height={22}></Icon>
        },
        {
            text: localeText?.common?.stock_out,
            slug: '/stock-out',
            icon: <Icon name="upload" width={22} height={22}></Icon>
        },
        {
            text: localeText?.common?.stock_audit,
            slug: '/stock-audit',
            icon: <Icon name="switch-vertical" width={22} height={22}></Icon>
        },
        {
            text: localeText?.common?.history,
            slug: '/history',
            icon: <Icon name="history" width={22} height={22}></Icon>
        },
        {
            text: localeText?.common?.contact,
            slug: '/contact',
            icon: <Icon name="user-group" width={22} height={22}></Icon>,
            submenu: [
                {
                    text: localeText?.common?.customer,
                    slug: '/customer'
                },
                {
                    text: localeText?.common?.supplier,
                    slug: '/supplier'
                },
                {
                    text: localeText?.common?.staff,
                    slug: '/staff'
                }
            ]
        }

        // {
        //     text: locale.id.common.customer,
        //     slug: '/customer',
        //     icon: <Icon name="user-group" width={22} height={22}></Icon>
        // },
        // {
        //     text: locale.id.common.supplier,
        //     slug: '/supplier',
        //     icon: <Icon name="box-car" width={22} height={22}></Icon>
        // },
        // {
        //     text: locale.id.common.staff,
        //     slug: '/staff',
        //     icon: <Icon name="users-outline" width={22} height={22}></Icon>
        // }
        // {
        //     text: locale.id.common.category,
        //     slug: '/category',
        //     icon: <Icon name="category" width={22} height={22}></Icon>
        // },
    ]

    return (
        <main className="w-full h-full bg-neutrals-100 flex flex-col">
            <div className="flex-grow flex overflow-hidden relative">
                {!hideNavigation && (
                    <Sidebar
                        shrink={active}
                        showMobileSidebar={showMobileSidebar}
                        prepend={
                            <div className={`${active ? 'px-2 mb-2' : 'px-4'} mt-4`}>
                                <DropdownWrapper
                                    value={getCurrentBusiness()}
                                    options={businesses.map((item) => ({
                                        text: item.name,
                                        value: item.businessId
                                    }))}
                                    onSelect={({ value }) => {
                                        setCurrentBusiness(value)
                                        const currentBusiness = businesses.find((item) => item.businessId === value)
                                        $amplitude.identify(value, {
                                            'Business Name': currentBusiness?.name,
                                            'Business Category': currentBusiness?.category
                                        })
                                        delayedCallback(() => {
                                            window.location.href = `/business/product`
                                        }, 250)
                                    }}
                                    onOpen={() => setActive(false)}
                                    append={
                                        <div className="p-4 border-t">
                                            <Button
                                                size="sm"
                                                fullWidth
                                                text={localeText.common.add}
                                                icon={<Icon name="plus-circle-outline" size="lg"></Icon>}
                                                onClick={() => router.push('/business')}></Button>
                                        </div>
                                    }>
                                    <Button
                                        size="md"
                                        text={!active ? currentBusiness()?.name : undefined}
                                        icon={<Icon name="selector" size="lg"></Icon>}
                                        type="outlined"
                                        iconPosition="right"
                                        fullWidth
                                        spaceBetweenIconText></Button>
                                </DropdownWrapper>
                            </div>
                        }
                        append={
                            <div className="px-4 pb-4">
                                <DropdownWrapper
                                    anchor="top"
                                    dropdownMinWidth={200}
                                    onOpen={() => setProfileDropdownState(true)}
                                    onClose={() => setProfileDropdownState(false)}
                                    onSelect={({ value }) => {
                                        if (value === 'logout') {
                                            logout()
                                            return
                                        }

                                        if (value === 'account') {
                                            router.push(`/business/${value}`)
                                            return
                                        }

                                        if (value === 'feedback') {
                                            window.open('https://feedback.kelola.co/saran-fitur', '_blank')
                                        }

                                        if (value === 'contact') {
                                            window.open('https://wa.me/+919929970571', '_blank')
                                        }
                                    }}
                                    options={[
                                        {
                                            text: localeText?.common?.logout,
                                            value: 'logout',
                                            icon: <Icon name="logout"></Icon>
                                        },
                                        {
                                            text: localeText?.common?.setting,
                                            value: 'account',
                                            icon: <Icon name="cog"></Icon>
                                        },
                                        {
                                            text: localeText?.common?.contact_us,
                                            value: 'contact',
                                            icon: <Icon name="chat-outline"></Icon>
                                        },
                                        {
                                            text: localeText?.common?.feedback,
                                            value: 'feedback',
                                            icon: <Icon name="information-circle"></Icon>
                                        }
                                    ]}>
                                    <div
                                        role="button"
                                        className={`flex items-center cursor-pointer px-3 py-3 mb-4 rounded-full ${
                                            profileDropdownState ? 'bg-primary-100' : 'hover:bg-primary-100'
                                        }`}>
                                        <div className="w-7 h-7 mr-3 rounded-full flex items-center justify-center overflow-hidden bg-neutrals-200 flex-shrink-0">
                                            {profile && profile.imageUrl.length > 0 && (
                                                <Image
                                                    src={profile.imageUrl}
                                                    width={28}
                                                    height={28}
                                                    unoptimized
                                                    alt={''}
                                                    onError={() =>
                                                        $sentry.sendSentryEvent(
                                                            'Error loading image: ' + profile.imageUrl
                                                        )
                                                    }></Image>
                                            )}
                                        </div>
                                        <div className="truncate">
                                            {profile?.name !== '' ? profile?.name : profile?.phoneNumber}
                                        </div>
                                        <Spacer></Spacer>
                                        <Icon name="dots-vertical" color={COLORS.MAIN.NEUTRALS[400]}></Icon>
                                    </div>
                                </DropdownWrapper>
                                <p className="flex justify-center">
                                    <Link href={'http://simplystock.co'} className="text-sm text-neutrals-400">
                                        simplystock.co
                                    </Link>
                                </p>
                            </div>
                        }>
                        <>
                            {!isSubscribed && (
                                <div
                                    className={`${
                                        active ? 'px-0' : 'p-2'
                                    } hover:bg-amber-50 flex items-center rounded-lg`}
                                    role="button"
                                    onClick={() => execFeature()}>
                                    <Icon name="premium-outline" color={COLORS.SEMANTIC.AMBER['600']} size="xl"></Icon>
                                    <div className="w-3"></div>
                                    <p className="font-bold bg-gradient-to-r to-amber-400 from-amber-600 bg-clip-text inline-block text-transparent underline">
                                        {localeText?.common?.headerDashboard}
                                    </p>
                                </div>
                            )}
                            {/* {navigationMenu.map((item) =>
                                item.slug === '/report' && !checkAccess('readAccountingReport') ? (
                                    <Fragment key={item.slug}></Fragment>
                                ) : item.slug === '/stock-report' && !checkAccess('readStockReport') ? (
                                    <Fragment key={item.slug}></Fragment>
                                ) : (
                                    <SidebarListItem
                                        active={item.slug === parentRoute}
                                        key={item.slug}
                                        icon={item.icon}
                                        shrink={active}
                                        onClick={() => {
                                            if (item.slug === '/contact') {
                                                return
                                            }
                                            handClickItem(item.slug)
                                        }}
                                        subitem={
                                            item.submenu &&
                                            item.submenu.map((subitem) =>
                                                subitem.slug === '/staff' && !haveAccessToManageStaff() ? (
                                                    <Fragment key={subitem.slug}></Fragment>
                                                ) : (
                                                    <SidebarListSubitem
                                                        active={subitem.slug === parentRoute}
                                                        key={subitem.slug}
                                                        onClick={() => handClickItem(subitem.slug)}>
                                                        {subitem.text}
                                                    </SidebarListSubitem>
                                                )
                                            )
                                        }>
                                        {item.text}
                                    </SidebarListItem>
                                )
                            )} */}
                            {navigationMenu.map((item) => (
                                <SidebarListItem
                                    active={item.slug === parentRoute}
                                    key={item.slug}
                                    icon={item.icon}
                                    shrink={active}
                                    onClick={() => {
                                        if (item.slug === '/contact') {
                                            return
                                        }
                                        handClickItem(item.slug)
                                    }}
                                    subitem={
                                        item.submenu &&
                                        item.submenu.map((subitem) =>
                                            subitem.slug === '/report' && !checkAccess('readAccountingReport') ? (
                                                <Fragment key={subitem.slug}></Fragment>
                                            ) : subitem.slug === '/stock-report' && !checkAccess('readStockReport') ? (
                                                <Fragment key={subitem.slug}></Fragment>
                                            ) : subitem.slug === '/customer/report' &&
                                              !checkAccess('readStockReport') ? (
                                                <Fragment key={subitem.slug}></Fragment>
                                            ) : subitem.slug === '/staff' && !haveAccessToManageStaff() ? (
                                                <Fragment key={subitem.slug}></Fragment>
                                            ) : (
                                                <SidebarListItem
                                                    active={subitem.slug === parentRoute}
                                                    key={subitem.slug}
                                                    onClick={() => handClickItem(subitem.slug)}>
                                                    {subitem.text}
                                                </SidebarListItem>
                                            )
                                        )
                                    }>
                                    {item.text}
                                </SidebarListItem>
                            ))}
                        </>
                    </Sidebar>
                )}

                <div className="bg-neutrals-50 relative flex flex-col overflow-hidden flex-grow" id="right-hand-side">
                    {children}
                </div>
            </div>
        </main>
    )
}
