import { useRouter } from 'next/router'

import { Navbar, Spacer } from '~/components/Common'
import { KelolaLogo } from '~/components/Logo'

interface DashboardHeaderProps {
    /**
     * Header active state
     */
    active: boolean
    /**
     * On click menu event
     */
    onClickMenu: () => void
}

export const DashboardHeader = ({ active, onClickMenu }: DashboardHeaderProps) => {
    const router = useRouter()

    return (
        <Navbar active={active} onClickMenu={() => onClickMenu()}>
            <div className="flex items-center" data-testid="navbar_wrapper border-[2px]">
                <KelolaLogo
                    onClick={() => {
                        router.push('/business')
                    }}></KelolaLogo>
                <span className="ml-5 border-l border-neutrals-600 h-5"></span>
                <h1 className="pl-3 text-neutrals-600 text-lg leading-normal">Dashboard</h1>
            </div>
            <Spacer></Spacer>
        </Navbar>
    )
}
