import dayjs from 'dayjs'
import { createContext, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { GetTotalProduct, GetTotalStaff } from '~/api'
import locale from '~/locale'
import { RootState } from '~/redux/store'
import { BusinessI } from '~/types'
import { $log } from '~/utils/logger'
import { useAuth } from '../Auth/AuthProvider'
import { SubscriptionPaywall } from './SubscriptionPaywall'

interface SubscriptionProviderProps {
    children: JSX.Element | JSX.Element[]
}

type SubscriptionPlanT = 'free' | 'basic' | 'plus' | 'advance'

interface SubscriptionContextI {
    execFeature: (callback?: () => void) => void
    execFeatureByTier: (expectedTier: keyof typeof limitMap, callback?: () => void) => void
    execFeatureLimitCount: (feature: 'products' | 'staff', callback?: () => void) => void
    execFeatureLimitRange: (
        feature: 'history' | 'report' | 'stock_report',
        dateStart: string,
        callback?: () => void
    ) => void
    getSubscription: () => [boolean, SubscriptionPlanT, BusinessI['subscription']]
    isFreeTimeLimitExceeded: (numDays: number) => boolean
}

const SubscriptionContext = createContext<SubscriptionContextI>({
    execFeature: () => {},
    execFeatureByTier: () => {},
    execFeatureLimitCount: () => {},
    execFeatureLimitRange: () => {},
    getSubscription: () => [false, 'free', undefined],
    isFreeTimeLimitExceeded: () => false
})

export const useSubscription = () => useContext(SubscriptionContext)

const limitMap = {
    free: {
        tier: 0,
        products: 100,
        staff: 1,
        history: 7,
        report: 7,
        stock_report: 7
    },
    basic: {
        tier: 1,
        products: 500,
        staff: 3,
        history: 180,
        report: 180,
        stock_report: 180
    },
    plus: {
        tier: 2,
        products: 2000,
        staff: 5,
        history: 360,
        report: 360,
        stock_report: 360
    },
    advance: {
        tier: 3,
        products: 5000,
        staff: 25,
        history: 9999,
        report: 9999,
        stock_report: 9999
    }
}

export const SubscriptionProvider = ({ children }: SubscriptionProviderProps) => {
    const language = useSelector((state: RootState) => state.language.language)
    // Assuming locale has a specific type, e.g. LocaleType
    const localeText = (locale[language as keyof typeof locale] || locale.en) as any

    const { currentBusiness } = useAuth()
    const [showPaywall, setShowPaywall] = useState(false)

    const getSubscription = (): [boolean, SubscriptionPlanT, BusinessI['subscription']] => {
        const business = currentBusiness()

        if (!business) {
            return [false, 'free', undefined]
        }

        const subscription = business.subscription

        console.log(true, 'adcance', subscription)
        if (!subscription || dayjs().isAfter(dayjs(subscription.endDate).local())) {
            return [false, 'free', subscription]
        }

        return [true, subscription.productId.split('_')[1] as 'basic' | 'plus' | 'advance', subscription]
    }

    const execFeature = (callback: () => void = () => false) => {
        const [isSubscribed] = getSubscription()
        console.log('isSubscribed', isSubscribed)

        if (!isSubscribed) {
            openPaywall()
            return
        }

        callback()
    }

    const execFeatureByTier = (expectedTier: keyof typeof limitMap, callback: () => void = () => false) => {
        const [isSubscribed, plan] = getSubscription()

        // if (!isSubscribed || limitMap[plan].tier < limitMap[expectedTier].tier) {
        if (!isSubscribed) {
            openPaywall()
            return
        }

        callback()
    }

    const execFeatureLimitCount = async (feature: 'products' | 'staff', callback: () => void = () => false) => {
        const bussinessId = currentBusiness()?.businessId || ''
        const count =
            feature === 'products'
                ? await GetTotalProduct(bussinessId, localeText).then((res) => res.data || 0)
                : await GetTotalStaff(bussinessId, localeText).then((res) => res.data || 0)

        const [_, plan] = getSubscription()
        console.log('asasas', _)

        if (!_) {
            openPaywall()
            return
        }

        callback()
    }

    const execFeatureLimitRange = (
        feature: 'history' | 'report' | 'stock_report',
        dateStart: string,
        callback: () => void = () => false
    ) => {
        const dateDiff = dayjs().diff(dayjs(dateStart), 'day')
        const [_, plan] = getSubscription()

        if (!_) {
            openPaywall()
            return
        }

        callback()
    }

    const isFreeTimeLimitExceeded = (numDays: number) => {
        const [isSubscribed] = getSubscription()

        if (isSubscribed) {
            return false
        }

        const dateBusinessCreated = dayjs(currentBusiness()?.createdAt).local()
        const dateLimit = dateBusinessCreated.add(numDays, 'day')

        return dateLimit.isBefore(dayjs())
    }

    const openPaywall = () => {
        try {
            AppChannel.postMessage('showPaywall')
            $log('Open Paywall', 'Post Message')
        } catch (e) {
            setShowPaywall(true)
            $log('Open Paywall', 'Default')
        }
    }

    const value = {
        execFeature,
        execFeatureByTier,
        execFeatureLimitCount,
        execFeatureLimitRange,
        getSubscription,
        isFreeTimeLimitExceeded
    }

    return (
        <SubscriptionContext.Provider value={value}>
            {children}
            <SubscriptionPaywall show={showPaywall} onClose={() => setShowPaywall(false)}></SubscriptionPaywall>
        </SubscriptionContext.Provider>
    )
}
