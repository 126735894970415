import Head from 'next/head'

interface MetaProps {
    /**
     * Title
     */
    title?: string
    /**
     * Description
     */
    description?: string
    /**
     * Additional meta
     */
    children?: JSX.Element | JSX.Element[]
    /**
     * Use site name as prefix
     */
    useSiteName?: boolean
}

export const Meta = ({
    title = 'Simply - Simple Stock Inventory App',
    description = '',
    children,
    useSiteName = true
}: MetaProps) => {
    return (
        <>
            <Head>
                <title>{useSiteName ? 'simplystock.co - ' + title : title}</title>
                <meta name="description" content={description}></meta>
                <meta name="robot" content="noindex, nofollow"></meta>
                {children}
            </Head>
        </>
    )
}
