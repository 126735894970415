import { Dispatch, ReactElement, SetStateAction } from 'react'
import { Button, SelectCountry, TextField } from '~/components'
import { isNumberOnly } from '~/utils'

export interface PhoneNumber {
    /**
     * country code
     */
    countryCode: string
    /**
     * phone number
     */
    phoneNumber: string
}

export interface PhoneNumberFormProps {
    /**
     * Input phone number form title
     */
    phoneFormTitle?: string
    /**
     * default country code selected
     */
    defaultCountryCode?: string
    /**
     * phone input placeholder
     */
    phoneNumberPlaceholder?: string
    /**
     * default phone no. input
     */
    defaultPhoneNumber?: string
    /**
     * login button text
     */
    phoneFormButtonText?: string
    /**
     * additonal info text at bottom
     */
    bottomText?: JSX.Element | string
    /**
     * phone number error
     */
    phoneNumberError?: string
    /**
     * optional select country dropdown maximum height
     */
    selectCountryDropdownMaxHeight?: number
    /**
     * form loading state
     */
    phoneNumberFormLoading?: boolean
    /**
     * enable/disable edit phone number
     */
    editPhoneNumber?: boolean
    /**
     * on country select from dropdown
     */
    onSelectCountryCode?: (value: string) => void
    /**
     * on input phone number
     */
    onInputPhoneNumber?: (value: string) => void
    /**
     * on submit phone number form
     */
    onSubmitPhoneNumber?: (value: PhoneNumber, nextStep: () => void) => void
    /**
     * on phone number error event
     */
    onPhoneNumberError?: (value: string) => void
}

type OTPPhoneNumberFormPropsType = PhoneNumberFormProps &
    PhoneNumber & {
        /**
         * Set country code
         */
        setCountryCode: Dispatch<SetStateAction<string>>
        /**
         * Set phone number
         */
        setPhoneNumber: Dispatch<SetStateAction<string>>
        /**
         * Handle login step
         */
        setStep: Dispatch<SetStateAction<number>>
    }

export const PhoneNumberForm = ({
    countryCode,
    phoneNumber,
    setCountryCode,
    setPhoneNumber,
    setStep,
    phoneFormTitle = 'Masukan Nomor HP Kamu',
    phoneNumberPlaceholder = '81234567890',
    phoneFormButtonText = 'Lanjut',
    bottomText,
    phoneNumberError = '',
    selectCountryDropdownMaxHeight = 150,
    phoneNumberFormLoading,
    editPhoneNumber = true,
    onSelectCountryCode,
    onInputPhoneNumber,
    onSubmitPhoneNumber,
    onPhoneNumberError = () => false
}: OTPPhoneNumberFormPropsType): ReactElement => {
    const handleSelectCountryCode = (val: string) => {
        setCountryCode(val)

        if (onSelectCountryCode) {
            onSelectCountryCode(val)
        }
    }

    const handleInputPhone = (val: string) => {
        const value = val.replace(/[^0-9]/g, '').replace(/\b0+/g, '')

        setPhoneNumber(value)
        onPhoneNumberError('')

        if (onInputPhoneNumber) {
            onInputPhoneNumber(value)
        }
    }

    const checkButtonDisabled = () => {
        if (phoneNumber.length >= 7 && phoneNumber.length <= 14) {
            return false
        }

        return true
    }

    const handleSubmitPhoneNumber = () => {
        if (checkButtonDisabled()) {
            return
        }

        if (!isNumberOnly(phoneNumber)) {
            onPhoneNumberError('invalid')
            return
        }

        if (onSubmitPhoneNumber) {
            onSubmitPhoneNumber({ countryCode, phoneNumber }, () => setStep(2))
        }
    }

    return (
        <section className="flex flex-col items-center">
            <div className="text-sm font-medium text-neutrals-900 mb-2">{phoneFormTitle}</div>
            <div className="flex relative w-full">
                <div className="mr-2 flex-shrink-0">
                    <SelectCountry
                        value={countryCode || ''}
                        width={119}
                        fitToParentBox
                        dropdownMaxHeight={selectCountryDropdownMaxHeight}
                        disabled={!editPhoneNumber}
                        onSelect={(val) => handleSelectCountryCode(val)}></SelectCountry>
                </div>
                <div className="flex-grow">
                    <TextField
                        variant={phoneNumberError === '' ? undefined : 'error'}
                        placeholder={phoneNumberPlaceholder}
                        onInput={(val) => handleInputPhone(val)}
                        disabled={!editPhoneNumber}
                        fullWidthInput
                        type="tel"
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                handleSubmitPhoneNumber()
                            }
                        }}
                        value={phoneNumber}></TextField>
                </div>
            </div>

            {phoneNumberError !== '' && <p className="text-red-500 mt-2">{phoneNumberError}</p>}

            <div className="my-6 text-neutrals-200 w-full" data-testid="input_button">
                <Button
                    text={phoneFormButtonText}
                    disabled={checkButtonDisabled()}
                    size={'md'}
                    fullWidth
                    loading={phoneNumberFormLoading}
                    onClick={() => handleSubmitPhoneNumber()}></Button>
            </div>
            <div className="text-center text-sm font-normal">
                {bottomText ? (
                    bottomText
                ) : (
                    <>
                        <p className="text-neutrals-900">Dengan melanjutkan artinya Anda menyetujui</p>
                        <a
                            className="text-primary-600 cursor-pointer no-underline"
                            href="https://simplystock.co/privacy"
                            target="_blank"
                            rel="noreferrer">
                            Kebijakan privasi
                        </a>
                    </>
                )}
            </div>
        </section>
    )
}
