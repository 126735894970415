import en from './en'
import hi from './hi'
import id from './id'

const locale = {
    id,
    en,
    hi
}

export default locale
