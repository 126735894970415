import { useRef, useState } from 'react'

import { useOnClickOutside } from '~/hooks'
import { Spacer } from '../Common'
import { SelectOption } from '../Form'
import { Dropdown } from './Dropdown'
import { DropdownListItem } from './DropdownListItem'

interface DropdownWrapperProps {
    /**
     * Min width of dropdown
     */
    dropdownMinWidth?: number
    /**
     * Prepend element to dropdown
     */
    prepend?: JSX.Element
    /**
     * Append element to dropdown
     */
    append?: JSX.Element
    /**
     * Dropdown parent
     */
    children: JSX.Element
    /**
     * Maximum height
     */
    maxHeight?: number
    /**
     * Disable input
     */
    disabled?: boolean
    /**
     * Match dropdown width to parent
     */
    fitToParentBox?: boolean
    /**
     * Options of select input
     */
    options?: SelectOption[]
    /**
     * Value of input
     */
    value?: any
    /**
     * Class name for the wrapper
     */
    className?: string
    /**
     * Position
     */
    position?: 'left' | 'right'
    /**
     * Anchor
     */
    anchor?: 'top' | 'bottom'
    /**
     * Dropdown item size
     */
    dropdownItemSize?: 'sm' | 'md' | 'lg'
    /**
     * Optional input event
     */
    onSelect?: (value: SelectOption) => void
    /**
     * Optional open dropdown event
     */
    onOpen?: () => void
    /**
     * Optional close dropdown event
     */
    onClose?: () => void
    /**
     * Align dropdown items
     */
    align?: 'start' | 'end' | 'center' // Add this line
    /**
     * Side of dropdown
     */
    side?: 'top' | 'bottom' // Add this line
}

export const DropdownWrapper = ({
    dropdownMinWidth,
    prepend,
    append,
    children,
    maxHeight = 300,
    disabled,
    fitToParentBox = false,
    options = [],
    value = '',
    position = 'left',
    onSelect,
    anchor = 'bottom',
    dropdownItemSize,
    onOpen = () => false,
    onClose = () => false
}: DropdownWrapperProps) => {
    const wrapper = useRef(null)
    const [isFocus, setFocus] = useState(false)

    const handleClickOutside = () => {
        setFocus(false)
        onClose()
    }

    const handleClickInside = () => {
        if (disabled) {
            return
        }

        setFocus(true)
        onOpen()
    }

    useOnClickOutside(wrapper, handleClickOutside)

    const handleSelect = (_value: any) => {
        if (!onSelect || disabled) {
            return
        }

        onSelect(_value)
        handleClickOutside()
    }

    return (
        <div className={`${fitToParentBox ? '' : 'relative'}`} ref={wrapper} onClick={handleClickInside}>
            {children}
            <Dropdown
                parentRef={wrapper}
                isFocus={isFocus}
                dropdownMinWidth={dropdownMinWidth}
                maxHeight={maxHeight}
                prepend={prepend}
                append={append}
                position={position}
                anchor={anchor}>
                {options.map((item, index) => (
                    <DropdownListItem
                        key={index}
                        isActive={value === item.value}
                        size={dropdownItemSize}
                        onClick={() => handleSelect(item)}>
                        <>
                            {item.icon && <span className="flex-shrink-0 mr-2 flex items-center">{item.icon}</span>}
                            <span className="">{!!item.longText ? item.longText : item.text}</span>
                            <Spacer></Spacer>
                            {item.subtext && <span className="text-xs text-gray-500">{item.subtext}</span>}
                        </>
                    </DropdownListItem>
                ))}
            </Dropdown>
        </div>
    )
}
